import React from 'react'

const icon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.85065 1.60347C1.14213 0.651752 2.03642 0 3.0508 0H14.9462C15.9606 0 16.8548 0.651752 17.1463 1.60347C17.2077 1.80383 17.2742 2.01149 17.342 2.22342C17.6933 3.32071 18.0812 4.53227 17.9852 5.43717C17.8728 6.49643 16.8342 7.5 15.8896 7.5C14.6914 7.5 13.8783 6.49654 13.7916 5.43732C13.7832 5.33409 13.6983 5.25 13.5925 5.25C13.4868 5.25 13.402 5.33417 13.3911 5.43717C13.2787 6.49643 12.2401 7.5 11.2955 7.5C10.0974 7.5 9.28425 6.49654 9.19759 5.43732C9.18915 5.33409 9.10421 5.25 8.99849 5.25C8.89277 5.25 8.80799 5.33417 8.79706 5.43717C8.68466 6.49643 7.64609 7.5 6.70147 7.5C5.50334 7.5 4.6902 6.49654 4.60355 5.43732C4.5951 5.33409 4.51016 5.25 4.40444 5.25C4.29872 5.25 4.21394 5.33417 4.20301 5.43717C4.09061 6.49643 3.05204 7.5 2.10742 7.5C0.909292 7.5 0.0961522 6.49654 0.0094975 5.43732C-0.0662881 4.51087 0.325698 3.27031 0.678888 2.15252C0.738023 1.96537 0.79607 1.78167 0.85065 1.60347Z" />
    <path d="M2.23925 9C2.51294 9 2.74691 9.197 2.79353 9.46669L3.53488 13.7555C3.65919 14.4747 4.28311 15 5.01296 15H12.987C13.7169 15 14.3408 14.4747 14.4651 13.7555L15.2065 9.46669C15.2531 9.197 15.4871 9 15.7607 9H16.8073C17.0391 9 17.2153 9.20808 17.1772 9.43665L16.0633 16.1199C15.8825 17.2048 14.9439 18 13.844 18H4.15604C3.05615 18 2.11747 17.2048 1.93665 16.1199L0.822775 9.43665C0.784677 9.20808 0.960945 9 1.19267 9H2.23925Z" />
    <path d="M4.99149 12.4659C4.93232 12.2293 5.11133 12 5.35529 12H12.6447C12.8887 12 13.0677 12.2293 13.0085 12.4659L12.892 12.9319C12.8086 13.2658 12.5086 13.5 12.1644 13.5H5.83558C5.49143 13.5 5.19144 13.2658 5.10797 12.9319L4.99149 12.4659Z" />
  </svg>
)

export default icon
