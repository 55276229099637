import React from 'react'

const icon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.8792 1.03953C10.4721 1.43756 10.909 1.95401 11.4763 1.90425C11.8648 1.87016 12.2545 1.87002 12.6419 1.93833L21.8075 3.55449C22.2294 3.62887 22.511 4.03113 22.4367 4.45296L20.5511 15.1462C20.4768 15.568 20.0745 15.8497 19.6527 15.7753L18.2004 15.5192C17.6703 15.4258 17.2084 15.8876 17.3019 16.4177L17.736 18.8795C17.767 19.0554 17.7857 19.2306 17.7925 19.4042C17.8093 19.8273 18.0819 20.2237 18.499 20.2972L18.8446 20.3581C20.1101 20.5813 21.3169 19.7363 21.54 18.4708L23.9643 4.72233C24.1874 3.45682 23.3424 2.25004 22.0769 2.02688L12.9113 0.410738C12.1435 0.275351 11.3973 0.533165 10.8792 1.03953Z" />
    <path d="M16.7286 13.1664C16.7853 13.4875 17.0367 13.7389 17.3578 13.7956L18.3944 13.9784C18.8162 14.0527 19.232 13.7682 19.2095 13.3405C19.1384 11.9907 18.1764 11.0827 16.817 10.6218C16.5534 10.5324 16.3053 10.7658 16.3537 11.0399L16.7286 13.1664Z" />
    <path d="M16.6656 9.66225C16.3314 9.68044 16.0668 9.413 16.0087 9.08338L15.3118 5.13108L15.3057 5.09735C15.2274 4.6746 15.4194 4.21776 15.8486 4.19271C16.1816 4.17326 16.5515 4.20279 16.9554 4.27401C18.6427 4.57154 19.5221 5.34946 19.2246 7.0368C18.967 8.49782 18.0124 9.58898 16.6656 9.66225Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.10553 11.5101C3.7795 10.8223 3.41967 9.91802 3.6263 9.63789C3.859 9.32239 4.96605 9.58752 5.67281 9.79739C5.90787 9.86719 6.15976 9.83304 6.37961 9.72445C6.71102 9.56074 7.07238 9.44037 7.45695 9.37256C7.82006 9.30853 8.17979 9.29561 8.52806 9.32845C8.76555 9.35084 9.00642 9.29638 9.19858 9.15503C9.79276 8.71791 10.7104 8.11992 11.0303 8.33235C11.2969 8.50937 11.294 9.34567 11.2366 10.0682C11.2017 10.5069 11.3346 10.9387 11.532 11.3319C11.6805 11.6276 11.7886 11.9484 11.8486 12.289C11.9122 12.6495 12.2705 12.9675 12.5354 13.2023C12.6957 13.3443 12.7393 13.5939 12.5523 13.6982C12.376 13.7966 12.1626 13.8451 11.936 13.8607C11.7809 13.8713 11.6401 13.9585 11.5603 14.0919C11.0285 14.9804 9.91807 15.5327 8.58461 15.7679C7.2446 16.0041 6.00674 15.8624 5.2034 15.201C5.07852 15.0982 4.9085 15.0643 4.75491 15.1151C4.50707 15.1969 4.25767 15.2372 4.03136 15.2007C3.81991 15.1666 3.7755 14.9172 3.87756 14.7289C4.05595 14.3998 4.18324 14.0394 4.26211 13.663C4.29213 13.5196 4.29036 13.3722 4.2812 13.226C4.27084 13.0607 4.27242 12.8966 4.28529 12.7347C4.31851 12.3169 4.28505 11.8888 4.10553 11.5101ZM8.25675 14.2506C8.67859 14.1762 8.9904 13.9449 8.95321 13.734C8.91602 13.5231 8.54391 13.4124 8.12207 13.4868C7.70023 13.5612 7.38841 13.7924 7.42561 14.0034C7.46279 14.2143 7.83491 14.325 8.25675 14.2506ZM7.12411 13.0198C6.91559 13.5927 6.30602 13.8968 5.7626 13.699C5.2192 13.5012 4.94772 12.8765 5.15624 12.3035C5.36475 11.7306 5.97432 11.4265 6.51774 11.6243C7.06114 11.8221 7.33263 12.4469 7.12411 13.0198ZM10.4152 12.882C10.8585 12.51 10.8995 11.8291 10.5069 11.3612C10.1142 10.8932 9.43656 10.8154 8.99324 11.1874C8.54992 11.5594 8.50886 12.2403 8.9015 12.7083C9.29416 13.1762 9.97184 13.254 10.4152 12.882Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.9231 5.12922C0.657595 5.35237 -0.187411 6.55916 0.0357399 7.82467L2.45996 21.5731C2.6831 22.8386 3.88989 23.6836 5.1554 23.4605L14.321 21.8443C15.5865 21.6212 16.4315 20.4144 16.2084 19.1489L13.7842 5.40044C13.561 4.13493 12.3542 3.28993 11.0887 3.51308L1.9231 5.12922ZM2.19247 6.65683C1.77063 6.73121 1.48896 7.13346 1.56335 7.55531L3.44884 18.2485C3.52323 18.6704 3.92549 18.952 4.34733 18.8777L13.513 17.2615C13.9348 17.1871 14.2165 16.7849 14.1421 16.363L12.2566 5.66981C12.1822 5.24797 11.7799 4.96629 11.3581 5.04069L2.19247 6.65683Z"
    />
  </svg>
)

export default icon
