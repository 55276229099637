const alert = {
  visible: false,
  msg: '',
  positiveLabel: '',
  negativeLabel: '',
  positiveListener: null,
  negativeListener: null,
}

export default alert
