const vote = {
  visible: false,
  dapp: null,
  isUpvote: false,
  sntValue: '0',
  afterVoteRating: null,
  learnMoreUpVote: false,
  learnMoreDownVote: false,
}

export default vote
