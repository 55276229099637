import React from 'react'

const icon = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.87492 8.50002H6.62492C6.85201 8.50002 7.05681 8.36348 7.14415 8.15387L8.71387 4.38656C8.80945 4.15716 8.74317 3.89223 8.55083 3.73486L5.10612 0.916456C4.89891 0.746923 4.60093 0.746923 4.39373 0.916456L0.949012 3.73486C0.756674 3.89223 0.690396 4.15716 0.785978 4.38656L2.35569 8.15387C2.44303 8.36348 2.64784 8.50002 2.87492 8.50002Z" />
    <path d="M12.2499 1.00002C11.8357 1.00002 11.4999 1.3358 11.4999 1.75002V7.75002C11.4999 8.16424 11.8357 8.50002 12.2499 8.50002H18.2499C18.6641 8.50002 18.9999 8.16424 18.9999 7.75002V1.75002C18.9999 1.3358 18.6641 1.00002 18.2499 1.00002H12.2499Z" />
    <path d="M4.74992 19C6.82099 19 8.49992 17.3211 8.49992 15.25C8.49992 13.1789 6.82099 11.5 4.74992 11.5C2.67885 11.5 0.999923 13.1789 0.999923 15.25C0.999923 17.3211 2.67885 19 4.74992 19Z" />
    <path d="M11.508 19H18.9919C19.3335 19 19.5505 18.6344 19.3869 18.3345L15.645 11.4743C15.4744 11.1616 15.0254 11.1616 14.8549 11.4743L11.1129 18.3345C10.9494 18.6344 11.1664 19 11.508 19Z" />
  </svg>
)

export default icon
