import React from 'react'

const icon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path d="M10.7652 11.7348C10.6187 11.8812 10.3813 11.8812 10.2349 11.7348L3.43936 4.93929C3.00004 4.49995 2.62511 3.37493 3.00004 2.99994C3.37496 2.62495 4.50002 2.99994 4.93936 3.43929L11.7349 10.2348C11.8813 10.3812 11.8813 10.6187 11.7349 10.7651L10.7652 11.7348Z" />
      <path d="M11.4731 13.2769L13.277 11.473C13.8169 10.9374 14.6888 10.9388 15.2271 11.477C15.5538 11.8037 15.6956 12.2721 15.605 12.7251L15.5939 12.7807C15.4897 13.3013 15.9487 13.7602 16.4692 13.6561C16.957 13.5585 17.4613 13.7112 17.8131 14.063L19.875 16.125C20.9105 17.1605 20.9105 18.8394 19.875 19.875C18.8395 20.9105 17.1606 20.9105 16.125 19.875L14.0631 17.813C13.7113 17.4612 13.5586 16.957 13.6562 16.4692C13.7603 15.9486 13.3013 15.4897 12.7808 15.5938L12.7252 15.6049C12.2721 15.6955 11.8038 15.5537 11.4771 15.227C10.9388 14.6888 10.9375 13.8169 11.4731 13.2769Z" />
      <path d="M17.444 11.9008C17.2474 11.9427 17.0648 11.8043 17.0061 11.6121C16.8702 11.1673 16.6267 10.7553 16.2878 10.4164C15.5489 9.67751 14.5086 9.42373 13.5627 9.65504C13.4249 9.68874 13.2771 9.65567 13.1768 9.55537L12.4167 8.79526C12.2155 8.5941 12.0693 8.34066 12.0343 8.05833C12.0117 7.87538 12 7.68903 12 7.49995C12 5.01467 14.0148 2.99995 16.5 2.99995C16.8317 2.99995 17.1551 3.03584 17.4663 3.10395C17.7346 3.16264 17.8096 3.49041 17.6154 3.68456L15.6 5.69995C14.8545 6.44554 14.8545 7.65437 15.6 8.39995C16.3456 9.14554 17.5545 9.14554 18.3 8.39996L20.3154 6.38457C20.5096 6.19041 20.8374 6.26544 20.896 6.53367C20.9641 6.84494 21 7.16825 21 7.49995C21 9.66145 19.4761 11.467 17.444 11.9008Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.201 16.0469C10.3214 16.1976 10.3356 16.4138 10.2139 16.5636L7.67217 19.692C6.77792 20.7926 5.12782 20.8777 4.12505 19.875C3.12228 18.8722 3.20739 17.2221 4.30803 16.3278L8.67922 12.7762C8.82837 12.655 9.04498 12.6662 9.18086 12.8021L9.50636 13.1276C9.63675 13.258 9.67892 13.4501 9.63949 13.6303C9.45817 14.4587 9.64618 15.3525 10.201 16.0469ZM7.31254 18.1875C7.31254 19.0159 6.64096 19.6875 5.81254 19.6875C4.98411 19.6875 4.31254 19.0159 4.31254 18.1875C4.31254 17.359 4.98411 16.6875 5.81254 16.6875C6.64096 16.6875 7.31254 17.359 7.31254 18.1875Z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="19.5" height="19.5" transform="translate(2.25 2.25)" />
      </clipPath>
    </defs>
  </svg>
)

export default icon
