const submit = {
  visible_submit: false,
  visible_rating: false,
  id: '',
  email: '',
  name: '',
  desc: '',
  url: '',
  img: '',
  category: '',
  imgControl: false,
  imgControlZoom: 0,
  imgControlMove: false,
  imgControlX: 0,
  imgControlY: 0,
  sntValue: '0',
}

export default submit
