import React from 'react'

const icon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="white"
      d="M19.1,27.5c-0.5,0-1-0.3-1.3-0.7c-0.3-0.6-0.2-1.4,0.3-1.9l0,0c1.1-1.1,1.1-2.8,0-3.9c-0.5-0.5-1.3-0.8-2-0.8
      H7.1c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5l0,0h19.4c0.8,0,1.5,0.7,1.5,1.5c0,0.4-0.2,0.8-0.4,1.1l-7.4,7.4
      C19.9,27.4,19.5,27.5,19.1,27.5z"
    />
    <path
      fill="white"
      d="M26.5,14.8H7.1c-0.8,0-1.5-0.7-1.5-1.5c0-0.4,0.2-0.8,0.4-1.1l7.4-7.4c0.6-0.6,1.5-0.6,2.1,0
      c0.6,0.6,0.6,1.5,0,2.1c0,0,0,0,0,0l0,0c-1.1,1.1-1.1,2.9,0,4c0.5,0.5,1.2,0.8,2,0.8h9c0.8,0,1.5,0.7,1.5,1.5S27.3,14.8,26.5,14.8
      L26.5,14.8z"
    />
  </svg>
)

export default icon
