import React from 'react'

const icon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 6C2.68629 6 0 8.68629 0 12C0 15.3137 2.68629 18 6 18H6.25685C7.52346 18 8.72843 17.4532 9.5625 16.5L10.1342 15.8466C10.605 15.3086 11.2851 15 12 15C12.7149 15 13.395 15.3086 13.8658 15.8466L14.4375 16.5C15.2716 17.4532 16.4765 18 17.7432 18H18C21.3137 18 24 15.3137 24 12C24 8.68629 21.3137 6 18 6H6ZM6 8.25C6.62132 8.25 7.125 8.75368 7.125 9.375V10.5C7.125 10.7071 7.29289 10.875 7.5 10.875H8.625C9.24632 10.875 9.75 11.3787 9.75 12C9.75 12.6213 9.24632 13.125 8.625 13.125H7.5C7.29289 13.125 7.125 13.2929 7.125 13.5V14.625C7.125 15.2463 6.62132 15.75 6 15.75C5.37868 15.75 4.875 15.2463 4.875 14.625V13.5C4.875 13.2929 4.70711 13.125 4.5 13.125H3.375C2.75368 13.125 2.25 12.6213 2.25 12C2.25 11.3787 2.75368 10.875 3.375 10.875H4.5C4.70711 10.875 4.875 10.7071 4.875 10.5V9.375C4.875 8.75368 5.37868 8.25 6 8.25ZM18.375 13.875C18.375 14.7034 17.7034 15.375 16.875 15.375C16.0466 15.375 15.375 14.7034 15.375 13.875C15.375 13.0466 16.0466 12.375 16.875 12.375C17.7034 12.375 18.375 13.0466 18.375 13.875ZM20.625 11.625C21.4534 11.625 22.125 10.9534 22.125 10.125C22.125 9.29657 21.4534 8.625 20.625 8.625C19.7966 8.625 19.125 9.29657 19.125 10.125C19.125 10.9534 19.7966 11.625 20.625 11.625Z"
    />
  </svg>
)

export default icon
