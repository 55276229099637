import React from 'react'

const SocialNetworksIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12V18.0013C3 19.6581 4.34225 21 5.9991 21H12ZM13.5 12C13.5 12.8284 12.8284 13.5 12 13.5C11.1716 13.5 10.5 12.8284 10.5 12C10.5 11.1716 11.1716 10.5 12 10.5C12.8284 10.5 13.5 11.1716 13.5 12ZM16.5 13.5C17.3284 13.5 18 12.8284 18 12C18 11.1716 17.3284 10.5 16.5 10.5C15.6716 10.5 15 11.1716 15 12C15 12.8284 15.6716 13.5 16.5 13.5ZM9 12C9 12.8284 8.32844 13.5 7.5 13.5C6.67156 13.5 6 12.8284 6 12C6 11.1716 6.67156 10.5 7.5 10.5C8.32844 10.5 9 11.1716 9 12Z"
    />
  </svg>
)

export default SocialNetworksIcon
